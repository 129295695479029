import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../header";
import "../../../static/css/bootstrap.min.css";
import "../../../static/css/style.css";
import "../../../static/css/aos.css";
import Footer from "../footer";

import AOS from "aos";
function layout(props) {
    if (typeof window !== "undefined"){
        AOS.init();
    }
    const [headimg] = useState(props.headimg);
    const [headclr] = useState(props.headclr);
    const [headbg] = useState(props.headbg);
    const [call] = useState(props.callicon)
    return (
        <div>
            <Helmet>

            <title>Redsqware</title>
            </Helmet>
            <Header logo={headimg} listcolor={headclr} background={headbg} call={call}/>
            {props.children}
            <Footer/>
        </div>
    )
}
export default layout;