import React from 'react'

export default () => (
    <style jsx="true">{`
#contact-p-map{
width:100%;
height:600px;
 background-image: url(/images/contact-map.webp);
    background-size: cover;
    background-repeat:no-repeat;
}
    `}</style>
)