import React from 'react'

export default () => (
    <style jsx="true">{`
.contact-us-text-area .services-detail-text{
    text-align: center;
    line-height: 45px;
    max-width: 790px;
    margin: 0 auto;
}
.contact-number-col,.contact-email-col,.contact-adress-col{
    min-height: 100px;
    background-repeat: no-repeat;
    border-right: 1px solid #cdcdcd;
    background-position-x: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;

}
.contact-adress-col{
    border: none
}
.contact-number-col{
    background-image: url(/images/phone.webp);
}
.contact-email-col{
    background-image: url(/images/envolope.webp);
}
.contact-adress-col{
    background-image: url(/images/map-icon.webp);
}
.contact-page-box-col{
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0px;
}
.contact-page-box-col span{
    color: #d82b41;
    font-weight: 600;
}
.rocket-img-col{
    background-image: url(/images/form-img.webp);
    background-position: top;
    background-repeat: no-repeat
}
.reb-btn-bg{
    width: 100%;
    font-size: 20px;
    font-weight: 600;
}
.contact-in-touch{
    padding: 50px 0px;
}
.contact-us-form-section {
    padding: 70px 0px;
}
.write-form .form-control {
    border: none;
    border-bottom: 1px solid #23252a;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 35px;
    padding: 0 0 15px 0;
    color: #73777e;
    resize: none;
}
.in-touch-columns {
    display: flex;
    justify-content: space-between;
}

    `}</style>
)