import React from "react";
import Style from "./style"
function contactPageForm() {
    return(
        <section className="contact-us-form-section">
            <div className="container">
                <div className="contact-us-text-area">
                    <h3 className="services-detail-text">Chances are, there’s a
                        reason you visited us today.<b> Goals. Plans. A vision for
                            the future.</b> Why not give us seven
                        minutes to talk about how we can help you<b> realize them?</b> </h3>
                </div>

                <div className="col-sm-12 contact-page-box-col">
                    <div className="col-sm-4 contact-number-col">
                        <h3 className="services-small-text"><span>Call:</span>215.301.3699<br/><span>Toll Free:</span> 855.733.7792</h3>
                    </div>
                    <div className="col-sm-4 contact-email-col">
                        <h3 className="services-small-text">
                            info@redsqware.com</h3>
                    </div>
                    <div className="col-sm-4 contact-adress-col">
                        <h3 className="services-small-text">417 N 8th Street <br/>
                            Floor 4 <br/>
                            Philadelphia, PA 19123</h3>
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="in-touch-columns contact-in-touch">
                        <div className="col-sm-5">
                            <form className="write-form">
                                <div className="form-group">
                                    <input type="text" placeholder="Name" className="form-control"/>
                                        <input type="email" placeholder="E-mail" className="form-control"/>
                                            <input type="text" placeholder="Website" className="form-control"/>
                                                <textarea placeholder="How we can help" className="form-control" rows="3"></textarea>
                                                <button className="btn red-btn reb-btn-bg">CONTACT US</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-5 rocket-img-col"></div>
                    </div>
                </div>
            </div>
            <Style/>
        </section>
    )
}
export default contactPageForm;