import React from "react";
import Layout from "../components/layout";
import ContactUsComponent from "../components/contactpagecomponent"

function contactUs() {
    return (
        <Layout headimg={"/images/top-logo-2.webp"} headclr={"black-list"} headbg={"whitee-bg"} callicon ={"/images/call-answer (1).webp"}>
            <ContactUsComponent />
        </Layout>
    )
}
export default contactUs;