import React from 'react'

export default () => (
    <style jsx="true">{`
.footer-subscribe{
    background-color: #1e1f22;
    padding: 25px 0;
    text-align: center;
    padding-top: 30px;
}
.subscribe-content{
    display: inline-flex;
    align-items: center
}
.subscribe-content .services-detail-text{
    margin: 0px;
    padding-right: 30px
}
.footer{
    background-color: #232529;
}

.footer-icons-div{
    padding: 0px;
}
.footer-icons-div li{
    color: white;
    list-style: none;
    float: left;
    margin: 16px 24px 16px 0px;
}
.footer-icons-div li:hover{
    color: #ff003c;
    cursor: pointer
}
.rights-text{
    margin-top: 56px;
    color: #73777e
}
.footer .col-sm-12{
    display: flex;
    padding: 55px 0px;
}
.services-font-text{
font-size: 14px;
color: #73777e;
line-height: 23px;
}
.footer-heading{
    color: white;
    font-size: 20px;
}
.Recomended-column ul{
    padding: 0px;
}
.Recomended-column ul li {
    color: white;
    list-style: none;
    font-size: 15px;
    margin-bottom: 10px;
}
.Recomended-column ul li span{
    font-size: 12px;
    color: #515358;
}
.Recomended-column ul li:hover{
    color: #ff003c;
    cursor: pointer
}
.footer-img-col{
    padding-top: 50px;
}
    `}</style>
)