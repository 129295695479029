import  React from "react";
import ContactMap from "../contactmap";
import ContactForm from "../contactpageform"
function contactPageComponent() {
    return(
        <div>
            <ContactMap/>
            <ContactForm/>
        </div>
    )
}
export default contactPageComponent;