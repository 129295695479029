import React from "react";
import  Style from "./style"
import { Link } from "gatsby"
function Header(props) {
    return (
        <div className="container-fluid p-0">
            <header className={"main-header " + props.background}>
                <div className="container">

                    <nav className="navbar">
                       <Link to="/"> <div className="col-md-4"> <div className="navbar-brand"><img src={"" + props.logo}
                                                                                   className="img-responsive"
                                                                                          alt="logo"/></div></div></Link>
                        <div className={"col-md-8 " + props.listcolor }>
                            <ul className="header-list">
                                <Link to="/about"><li>About</li></Link>
                                <Link to="/case-studies"><li>Case Studies</li></Link>
                                <Link to="/what-we-do"><li>What We Do</li></Link>
                                <Link to="/blogs"><li>Our Blog</li></Link>
                                <Link to="/contactus"><li>Contact Us</li></Link>
                                <li><img src={props.call} alt=""/>215 301 3699</li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
            <Style/>
        </div>
    )

}
export default Header;