import React from "react";
import Style from "./style"

function Footer() {
    return(
        <div>
            {/*<!-- Footer Starts -->*/}
            <section className="footer-subscribe">
                <div className="container">
                    <div className="subscribe-content">
                        <p className="services-detail-text">Stay in touch, subscribe to our newsletter feed</p>
                        <button className="btn red-btn">Subscribe</button>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <div className="container">
                    <div className="col-sm-12">

                        <div className="col-sm-3 footer-img-col">


                            <img src="/images/footr-logo.webp" alt=""/>

                                <ul className="footer-icons-div">
                                    <li><img src="/images/behance-logo.webp" alt=""/></li>
                                <li><img src="/images/dribbble-logo.webp" alt=""/></li>
                                <li><img src="/images/pinterest-logo.webp" alt=""/></li>
                                <li><img src="/images/twitter-logo-silhouette.webp" alt=""/></li>
                            </ul>
                            <h2 className="services-detail-text rights-text">© 2017 Redsqware <br/> All rights reserved</h2>
                        </div>

                        <div className="col-sm-3">
                            <h3 className="services-small-heading footer-heading">About Us</h3>
                            <p className="services-detail-text services-font-text">Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aenean sodales volutpat
                                metus non pellentesque. className="" aptent taciti sociosqu
                                ad litora torquent per conubia nostra, per inceptos
                                himenaeos.</p>
                        </div>
                        <div className="col-sm-3 Recomended-column">
                            <h3 className="services-small-heading footer-heading">Recomended posts</h3>
                            <ul>
                                <li>Inspired by clouds</li>
                                <li>Stumbled the concept</li>
                                <li>Top Deejay Headphones</li>
                                <li>Trust in your intuitions</li>
                                <li>When you are alone</li>
                            </ul>
                        </div>
                        <div className="col-sm-3 Recomended-column">
                            <h3 className="services-small-heading footer-heading">Recomended posts</h3>
                            <ul>
                                <li>Calm over the horizon<br />
                                    <span>June 4,2018</span>
                                </li>
                                <li>Inspired by clouds<br />
                                    <span>June 4,2018</span>
                                </li>
                                <li>Make it clean and simple<br/>
                                    <span>June 4,2018</span>
                                </li>

                            </ul>
                        </div>



                    </div>
                </div>
            </footer>
            <Style/>
        </div>
    )
}
export default Footer