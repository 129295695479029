import React from "react"
import Style from "./style"
function contactMap() {
    return (
        <section className="about-maps-section">
            <div className="big-map" id="contact-p-map">
            </div>
            <Style/>
        </section>
    )
}
export default contactMap;