import React from 'react'

export default () => (
    <style jsx="true">{`
.main-header{
    // background: linear-gradient(to right, rgba(182,46,64,1) 0%, rgba(221,33,58,1) 100%);
    // min-height: 610px;
   position:absolute;
   width:100%;
   top:0;
   z-index:1;
}
.header-list{
    list-style: none;
    float: right;
    padding-top:17px;
}
.header-list li{
    float: left;
    font-size: 13px;
    margin-left: 28px;
}
.main-header .header-list li a  {
    color: unset;
    text-decoration: none
}
.main-header .header-list li i{
    padding-right: 9px
}
.container-fluid{
padding:0!important;
}
.transparent-bg{
background-color:transparent!important;
}
.whitee-bg{
background-color:white!important;
}
.white-list{
color:white!important;
}
.black-list{
color:black!important;
}
a{
color:unset!important
}
  .header-list li img{
      height: 13px;
    margin-right: 4px;
  }

    `}</style>
)